import React from 'react'

const Sessions = () => {
    return (
        <>
            <div className="row mt-3" id='sessions'>
                <div class="d-flex p-3 ps-4 flex-column" style={{ background: 'linear-gradient(123.35deg, #080D2F 3.3%, rgba(17, 27, 90, 0.2) 100%)', borderRadius: '25px', color: '#8C8C8C' }}>
                    <div class="p-1 fw-bold text-white">Sessions</div>
                    <div className="p-1" style={{ fontSize: '12px' }}>This is a list of devices that have logged into your account. Remove those that you do not recognize.</div>
                    <div className="d-flex flex-row mt-3 ps-3 align-items-center" style={{ fontSize: '13px' }}>
                        <div className='me-auto'>Bucharest 68.133.163.201</div>
                        <div className='me-4'>EU</div>
                        <div class="btn p-1 ps-3 pe-3" style={{ width: 'content-fit', height: 'content-fit', color: '#06A581', fontSize: '14px' }}>See more <i class="fa-solid fa-arrow-right ms-1"></i></div>
                    </div>
                    <hr />
                    <div className="d-flex ps-3 flex-row mt-3 align-items-center" style={{ fontSize: '13px' }}>
                        <div className='me-auto'>Chrome on macOS</div>
                        <div className='me-4'>EU</div>
                        <div class="btn p-1 ps-3 pe-3" style={{ width: 'content-fit', height: 'content-fit', color: '#06A581', fontSize: '14px' }}>See more <i class="fa-solid fa-arrow-right ms-1"></i></div>
                    </div>
                    <hr />
                    <div className="d-flex ps-3 flex-row  mt-3 align-items-center" style={{ fontSize: '13px' }}>
                        <div className='me-auto'>Safari on iPhone</div>
                        <div className='me-4'>EU</div>
                        <div class="btn p-1 ps-3 pe-3" style={{ width: 'content-fit', height: 'content-fit', color: '#06A581', fontSize: '14px' }}>See more <i class="fa-solid fa-arrow-right ms-1"></i></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sessions
