import React, { useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { toast, ToastContainer } from "react-toastify";
import "react-phone-number-input/style.css";

const base_url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEVELOPMENT
    : process.env.REACT_APP_BASE_URL_PRODUCTION;

const DLSearch = () => {
  const [vehicleDetails, setvehicleDetails] = useState("");
  const [licenseNumber, setLicenseNumber] = useState();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [openModal, setopenModal] = useState(false);

  console.log("vehicle details ", licenseNumber);
  const handleChangeLicenseNumber = (event) => {
    setLicenseNumber(event.target.value);
  };

  const searchLicense = async () => {
    setLoading(true);
    try {
      const apiResponse = await fetch(`${base_url}/user/get_vehicle_info`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": sessionStorage.getItem("token"),
        },
        body: JSON.stringify({ vehicleNumber: licenseNumber }),
      });
      console.log("api response ", apiResponse);

      if (apiResponse.ok) {
        const details = await apiResponse?.json();
        console.log("true", details);
        toast.success("Successfully fetched Vehicle Details");
        setopenModal(true);
        setvehicleDetails(details);
      }

      // if (details?.data?.data?.message === "Record not found" || details?.data?.message === "Record not found") {
      //   toast.error('Failed to fetch Vehicle Details')
      //   console.error("Failed to fetch license details");
      //   return;
      // }
    } catch (error) {
      toast.error("Failed to fetch Vehicle Details");
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setopenModal(false);
  };

  return (
    <div className="res3 row p-2">
      {/* ----------------loading ---------------- */}
      {loading && (
        <div
          className="loading-backdrop"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            background: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <div className="spinner-border text-light" role="status">
            <div className="visually-hidden">Loading...</div>
          </div>
          <div className="text-white">loading...</div>
        </div>
      )}

      {/* vehicle number search  */}
      <div className="col-md-8 mx-auto ">
        <div
          className="col-lg-8 col-md-12 col-sm-8 mx-auto border"
          style={{ borderRadius: "19px", backgroundColor: "#0F1535" }}
        >
          <div className="search-header  text-white text-center py-2 mb-4 mt-3">
            <h2>Vehicle Number Search</h2>
          </div>

          <div
            className="input-group mb-3 align-items-center mx-auto "
            style={{ width: "80%" }}
          >
            <input
              className="form-control"
              type="text"
              placeholder="Vehicle Number "
              value={licenseNumber}
              onChange={handleChangeLicenseNumber}
            />
          </div>

          <div className="d-flex justify-content-between flex-row-reverse mb-4">
            <button
              className="btn btn-primary mx-auto"
              onClick={searchLicense}
              style={{
                maxWidth: "200px",
                borderRadius: "15px",
                fontSize: "13px",
              }}
            >
              Search Vehicle
            </button>
          </div>
        </div>
      </div>

      {/* Render vehicle details here */}
      {openModal && (
        <div className="license-details">
          {/* Display license details */}

          <div
            className="modal-content mx-auto mt-5"
            style={{
              backgroundColor: "rgba(0, 88, 191, 0.15)",
              boxShadow: "2px 2px 6px 0 rgba(0, 0, 0, 0.15)",
              border: "3px solid rgba(0, 88, 191, 1)",
              borderRadius: "12px",
              maxWidth: "650px",
            }}
          >
            <div
              className="modal-body text-center "
              style={{
                backgroundColor: "rgba(0, 88, 191, 0.15)",
                boxShadow: "2px 2px 6px 0 rgba(0, 0, 0, 0.15)",
              }}
            >
              <div
                class="text-white "
                style={{ float: "right" }}
                onClick={closeModal}
              >
                <IoCloseCircleOutline size={25} />
              </div>
              <div className="CDR-row px-3 pb-4 p-3 pt-2 ">
                <div
                  className="table-responsive "
                  style={{ maxHeight: "900px" }}
                >
                  <div className="d-flex flex-column align-items-start text-white p-2 ">
                    <div className="w-100 d-flex  flex-column  ">
                      <strong className="p-2 fs-3 ">
                        {vehicleDetails.data.owner_name ||
                          vehicleDetails.data.result.owner_name}
                      </strong>
                      <p className=" fs-6 ">
                        Father's Name:{" "}
                        <strong>
                          {vehicleDetails.data.father_name ||
                            vehicleDetails.data.result.father_name ||
                            "NA"}
                        </strong>
                      </p>
                    </div>
                    <div className="w-100 d-flex align-items-center justify-content-between ">
                      <div className="d-flex flex-column align-items-start justify-content-center p-2">
                        <div className="d-flex align-items-center gap-2  p-2">
                          <p
                            className="mb-0 mr-2"
                            style={{
                              color: "rgba(206, 206, 206, 1)",
                              fontSize: "15px",
                            }}
                          >
                            Vehicle No. :{" "}
                          </p>{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            {vehicleDetails.data.rc_number ||
                              vehicleDetails.data.result.rc_number ||
                              "N/A"}
                          </span>
                        </div>
                        <div className="d-flex align-items-center gap-2  p-2">
                          <p
                            className="mb-0 mr-2"
                            style={{
                              color: "rgba(206, 206, 206, 1)",
                              fontSize: "15px",
                            }}
                          >
                            Fuel Type :{" "}
                          </p>{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            {vehicleDetails.data.fuel_type ||
                              vehicleDetails.data.result.fuel_type ||
                              "N/A"}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-column align-items-start justify-content-center ">
                        <div className="d-flex align-items-center gap-2  p-2">
                          <p
                            className="mb-0 mr-2"
                            style={{
                              color: "rgba(206, 206, 206, 1)",
                              fontSize: "15px",
                            }}
                          >
                            RC Status :{" "}
                          </p>{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            {vehicleDetails.data.rc_status ||
                              vehicleDetails.data.result.owner_name ||
                              "N/A"}
                          </span>
                        </div>
                        <div className="d-flex align-items-center gap-2  p-2">
                          <p
                            className="mb-0 mr-2"
                            style={{
                              color: "rgba(206, 206, 206, 1)",
                              fontSize: "15px",
                            }}
                          >
                            Seat Capacity :{" "}
                          </p>{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            {vehicleDetails.data.seat_capacity ||
                              vehicleDetails.data.result.seat_capacity ||
                              "N/A"}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex flex-column w-100 ">
                      <hr />
                      <strong
                        className="p-2"
                        style={{ color: "rgba(206, 206, 206, 1)" }}
                      >
                        {" "}
                        Address:{" "}
                      </strong>
                      <div className="w-100 d-flex align-items-center justify-content-between">
                        <div className="d-flex flex-column align-items-start justify-content-center p-2">
                          <div className="d-flex align-items-center gap-2  p-2">
                            <p
                              className="mb-0 mr-2"
                              style={{
                                color: "rgba(206, 206, 206, 1)",
                                fontSize: "15px",
                              }}
                            >
                              Permanent Address :{" "}
                            </p>{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {" "}
                              <span>
                                {vehicleDetails.data.permanent_address ||
                                  vehicleDetails.data.result
                                    .permanent_address ||
                                  "NA"}
                              </span>
                            </span>
                          </div>
                          {/* <div className="d-flex align-items-center gap-2  p-2">
                            <p
                              className="mb-0 mr-2"
                              style={{
                                color: "rgba(206, 206, 206, 1)",
                                fontSize: "15px",
                              }}>
                              Permanent Address :{" "}
                            </p>{" "}
                            <span>
                              {" "}
                              <span style={{ fontWeight: "bold" }}>
                                {vehicleDetails?.permanent_address || "N/A"}
                              </span>
                            </span>
                          </div> */}
                        </div>
                      </div>
                    </div>

                    <div style={{ width: "100%" }}>
                      <hr />
                      <strong
                        className="p-2  mx-auto "
                        style={{ color: "rgba(206, 206, 206, 1)" }}
                      >
                        {" "}
                        Vehicle Info :
                      </strong>

                      <div className=" d-flex align-items-center justify-content-between mt-3">
                        <div
                          className="d-flex flex-column align-items-start justify-content-center "
                          style={{ width: "100%" }}
                        >
                          <div className="d-flex align-items-center  p-2 ">
                            <p
                              className="mb-0 "
                              style={{
                                color: "rgba(206, 206, 206, 1)",
                                fontSize: "15px",
                                marginRight: "10px",
                              }}
                            >
                              Company :{" "}
                            </p>{" "}
                            <span>
                              {" "}
                              <span>
                                <span style={{ fontWeight: "bold" }}>
                                  {vehicleDetails.data.maker_description ||
                                    vehicleDetails.data.result
                                      .maker_description ||
                                    "N/A"}
                                </span>
                              </span>
                            </span>
                          </div>

                          <div className="d-flex align-items-center  p-2 ">
                            <p
                              className="mb-0 "
                              style={{
                                color: "rgba(206, 206, 206, 1)",
                                fontSize: "15px",
                                marginRight: "10px",
                              }}
                            >
                              Model :{" "}
                            </p>{" "}
                            <span>
                              {" "}
                              <span>
                                <span style={{ fontWeight: "bold" }}>
                                  {vehicleDetails.data.maker_model ||
                                    vehicleDetails.data.result.maker_model ||
                                    "N/A"}
                                </span>
                              </span>
                            </span>
                          </div>

                          <div className="d-flex align-items-center   p-2">
                            <p
                              className="mb-0 "
                              style={{
                                color: "rgba(206, 206, 206, 1)",
                                fontSize: "15px",
                                marginRight: "10px",
                              }}
                            >
                              Registration Date :{" "}
                            </p>{" "}
                            <span>
                              {" "}
                              <span>
                                <span style={{ fontWeight: "bold" }}>
                                  {vehicleDetails.data.registration_date ||
                                    vehicleDetails.data.result
                                      .registration_date}
                                </span>
                              </span>
                            </span>
                          </div>
                          <div className="d-flex align-items-center   p-2">
                            <p
                              className="mb-0"
                              style={{
                                color: "rgba(206, 206, 206, 1)",
                                fontSize: "15px",
                                marginRight: "10px",
                              }}
                            >
                              RC Blacklist Status:{" "}
                            </p>{" "}
                            <span>
                              {" "}
                              <span>
                                <span style={{ fontWeight: "bold" }}>
                                  {vehicleDetails?.data?.blacklist_status ||
                                    vehicleDetails?.data?.result
                                      ?.blacklist_status ||
                                    "N/A"}
                                </span>
                              </span>
                            </span>
                          </div>
                          <div className="d-flex align-items-center   p-2">
                            <p
                              className="mb-0"
                              style={{
                                color: "rgba(206, 206, 206, 1)",
                                fontSize: "15px",
                                marginRight: "10px",
                              }}
                            >
                              RC Validity:{" "}
                            </p>{" "}
                            <span>
                              {" "}
                              <span>
                                <span style={{ fontWeight: "bold" }}>
                                  {vehicleDetails.data.fit_up_to ||
                                    vehicleDetails.data.result.fit_up_to}
                                </span>
                              </span>
                            </span>
                          </div>
                          <div className="d-flex align-items-center  p-2 ">
                            <p
                              className="mb-0 "
                              style={{
                                color: "rgba(206, 206, 206, 1)",
                                fontSize: "15px",
                                marginRight: "10px",
                              }}
                            >
                              Chassis No :{" "}
                            </p>{" "}
                            <span>
                              {" "}
                              <span>
                                <span style={{ fontWeight: "bold" }}>
                                  {vehicleDetails.data.vehicle_chasi_number ||
                                    vehicleDetails.data.result
                                      .vehicle_chasi_number}
                                </span>
                              </span>
                            </span>
                          </div>

                          <div className="d-flex align-items-center   p-2">
                            <p
                              className="mb-0 "
                              style={{
                                color: "rgba(206, 206, 206, 1)",
                                fontSize: "15px",
                                marginRight: "10px",
                              }}
                            >
                              Engine No :{" "}
                            </p>{" "}
                            <span>
                              {" "}
                              <span>
                                <span style={{ fontWeight: "bold" }}>
                                  {vehicleDetails.data.vehicle_engine_number ||
                                    vehicleDetails.data.result
                                      .vehicle_engine_number}
                                </span>
                              </span>
                            </span>
                          </div>

                          <div className="d-flex align-items-center   p-2">
                            <p
                              className="mb-0 "
                              style={{
                                color: "rgba(206, 206, 206, 1)",
                                fontSize: "15px",
                                marginRight: "10px",
                              }}
                            >
                              Insurance Company :{" "}
                            </p>{" "}
                            <span>
                              {" "}
                              <span>
                                <span style={{ fontWeight: "bold" }}>
                                  {vehicleDetails.data.insurance_company ||
                                    vehicleDetails.data.result
                                      .insurance_company}
                                </span>
                              </span>
                            </span>
                          </div>

                          <div className="d-flex align-items-center   p-2">
                            <p
                              className="mb-0"
                              style={{
                                color: "rgba(206, 206, 206, 1)",
                                fontSize: "15px",
                                marginRight: "10px",
                              }}
                            >
                              Insurance Expiry Date :{" "}
                            </p>{" "}
                            <span>
                              {" "}
                              <span>
                                <span style={{ fontWeight: "bold" }}>
                                  {vehicleDetails.data.insurance_upto ||
                                    vehicleDetails.data.result.insurance_upto}
                                </span>
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DLSearch;
