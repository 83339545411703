import React, { useEffect, useState } from 'react';
import './activity.css';
const base_url =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_BASE_URL_DEVELOPMENT
    : process.env.REACT_APP_BASE_URL_PRODUCTION;
    
const Activity = () => {
  const [loading, setLoading] = useState(true);
  const [activityData, setActivityData] = useState([]);

  const formatDateTime = (dateTimeString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    const dateTime = new Date(dateTimeString);
    return dateTime.toLocaleDateString('en-GB', options);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${base_url}/user/list_activity`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-auth-token': sessionStorage.getItem('token'),
            },
          }
        );

        const json = await response.json();
        if (json.status === 200) {
          // Format createdAt and updatedAt before setting the state
          const formattedData = json.data.map((item) => ({
            ...item,
            createdAt: formatDateTime(item.createdAt),
            updatedAt: formatDateTime(item.updatedAt),
          }));
          setActivityData(formattedData);
        } else {
          throw new Error('Request was not successful');
        }
      } catch (error) {
        console.error('Failed to Fetch:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="px-4 mt-3">
      <div
        className="d-flex flex-column res1"
        style={{
          background:
            'linear-gradient(123.35deg, #080D2F 3.3%, rgba(17, 27, 90, 0.2) 100%)',
          color: '#8C8C8C',
          borderRadius: '10px'
        }}
      >
        <div className="p-3 pb-0 fw-bold text-white">Activity</div>
        <div
          className="table-responsive mb-3 ps-3 pe-3"
          style={{ maxHeight: '400px' }}
        >
          <table className="table mt-2" style={{ color: '#8C8C8C' }}>
            <thead
              className="sticky-top table-head"
              style={{ background: '#080D2F', fontSize: '12px' }}
            >
              <tr>
                <th scope="col">Action</th>
                <th scope="col">Created At</th>
                <th scope="col">Updated At</th>
              </tr>
            </thead>
            <tbody
              className="text-white"
              style={{ fontSize: '12px', background: '#080D2F' }}
            >
              {activityData.map((item) => (
                <tr key={item._id}>
                  <td scope="row" className="py-2">
                    {item.action}
                  </td>
                  <td style={{paddingTop: '14px',paddingBottom: '14px'}}>{item.createdAt}</td>
                  <td className="py-2">{item.updatedAt}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Activity;
