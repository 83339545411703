import React, { useEffect, useRef, useState } from "react";
import "../CSS/signup_signin.css";
import { Link, useNavigate } from "react-router-dom";
import "../CSS/d.css";
import { useFormik } from "formik";
import { loginSchema } from "../schemas";
import Swal from "sweetalert2";

const base_url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEVELOPMENT
    : process.env.REACT_APP_BASE_URL_PRODUCTION;

const CDRSignInPage = () =>
{
  // const alertRef = useRef();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ipaddress, setipaddress] = useState();
  const initialValues = {
    email: "",
    password: "",
    IPAddress: "",
  };

  useEffect(() =>
  {
    async function fetchData()
    {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      console.log(data.ip);
      localStorage.setItem("IPAddress", data.ip);
      setipaddress(data.ip);
    }
    fetchData();
  }, []);

  // useEffect(() =>
  // {
  //   alertRef.current.click();
  // }, []);


  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues: initialValues,
      validationSchema: loginSchema,
      onSubmit: async (values) =>
      {
        setLoading(true);
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) =>
          {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        values.IPAddress = ipaddress;
        try
        {
          const response = await fetch(`${base_url}/user/login`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          });
          const data = await response.json();
          if (data.status === 404 && data.success === false)
          {
            errors.password = "Invalid credentials";
          }

          if (data.status === 200)
          {
            sessionStorage.setItem("token", data.token);
            navigate("/");
          }
        } catch (error)
        {
          console.log("Login failed");
          Toast.fire({
            icon: "error",
            title: "Login failed",
          });
        } finally
        {
          setLoading(false);
        }
      },
    });

  const handleTogglePasswordVisibility = () =>
  {
    setShowPassword(!showPassword);
  };

  return (
    <>

      <div className="content-overlay">
        <div className="header d-flex flex-row align-items-center">
          <div className="fs-4 me-2"> C.A.T.C.H :</div>
          <div>
            CDR enabled Advanced Tracking of Crime using High end Technology
          </div>
        </div>
        <form onSubmit={handleSubmit} className="login-form mt-5">
          <h3 className="text-center w-100 mb-0">Login</h3>
          <div className="formContent" style={{ width: "100%" }}>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <span
              className="text-danger"
              style={{ fontSize: "12px", fontWeight: "900" }}
            >
              {errors.email && touched.email ? errors.email : null}
            </span>
            <br />
            <br />
            <label htmlFor="password">Password</label>
            <div className="password-input">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <i
                className={`password-toggle ${showPassword ? "fa fa-eye-slash" : "fa fa-eye"
                  }`}
                onClick={handleTogglePasswordVisibility}
              />
            </div>
            <span
              className="text-danger"
              style={{ fontSize: "12px", fontWeight: "900" }}
            >
              {errors.password && touched.password ? errors.password : null}
            </span>
            <div className="form-check form-switch mt-2">
              <input className="form-check-input" type="checkbox" />
              <label className="form-check-label">Remember me</label>
            </div>
            {loading ? (
              <div className="loader"></div>
            ) : (
              <button type="submit" disabled={loading}>
                SignIn
              </button>
            )}
            <p className="signup-link">
              <span>Don't have an account? </span>
              <Link to="/signup">Sign up</Link>
            </p>
          </div>
        </form>
      </div>
    </>
  );
};

export default CDRSignInPage;
