import React from "react";
import { Link } from "react-scroll";

const ProfileSidebar = () => {
  return (
    <>
      <div className="text-white sticky-top prof-sidebar mt-3 mb-3 sticky-profileSidebar">
        <div className="p-4 ps-4 pt-4">
          <Link to="profile">Profile</Link>
        </div>
        <div className="p-4 ps-4">
          <Link to="profileUpdate">Basic Information</Link>
        </div>
        <div className="p-4 ps-4">
          <Link to="password">Change Password</Link>
        </div>
        <div className="p-4 ps-4">
          <Link to="TFA">2FA</Link>
        </div>
        <div className="p-4 ps-4">
          <Link to="sessions">Sessions</Link>
        </div>
        {/* <div className="p-4 ps-4">
          <Link to="ApiDetails">Api Details</Link>
        </div> */}

        <div className="p-4 ps-4 pb-4">
          <Link to="delete">Delete Account</Link>
        </div>
      </div>
    </>
  );
};

export default ProfileSidebar;
